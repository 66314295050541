let environmentName = "prod";

let config = {
    errorApiHost: "https://api.errors.closesimple.com/",
    adminUiHost: "https://admin." + environmentName + ".web.cdn.closesimple.com/",
    diagnosticUiHost: "https://diagnostics.closesimple.com/",
    adminAuthApiHost: "https://api.auth.admin.closesimple.com/",
    adminAuthHost: "https://auth.admin.closesimple.com/",
    cognitoClientId: "3h3jsm5jesu6vcmeaa84qjoem1",
    searchLimit: 20,
}

config.tokenUrl = config.adminAuthHost + "authorize?response_type=code&client_id=" + config.cognitoClientId + "&redirect_uri=" + window.location.origin + "&scope=openid+profile+aws.cognito.signin.user.admin&idp_identifier=closesimple.com&state=";

config.getCompanyIdLink = (error) => {
    return `${config.adminUiHost}app/companies/${error.CompanyId}`
};

config.getOrderIdLink = (error) => {
    return `${config.diagnosticUiHost}company/${error.CompanyId}/order/${error.OrderId}`
};

module.exports = config;
